import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/home";
import Payed from "./components/pay/payed";
import Submit from "./pages/submit/submit";
import AiPrediction from "./pages/aiPrediction/aiPrediction";
import About from "./pages/about/about";
import History from "./pages/history/history";

export default function Router({ user, lang }) {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<About user={user} lang={lang} />} />
        <Route path="/about" element={<About user={user} lang={lang} />} />
        <Route path="/subject/:id" element={<Home user={user} lang={lang} />} />
        <Route path="/ai" element={<AiPrediction user={user} lang={lang} />} />
        <Route path="/payed" element={<Payed user={user} lang={lang} />} />
        <Route
          path="/join_racing"
          element={<Submit user={user} lang={lang} />}
        />
        <Route path="/history" element={<History user={user} lang={lang} />} />
      </Routes>
    </React.Fragment>
  );
}
