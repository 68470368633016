import React from "react";
import styles from "./index.scss";
import { Table, Typography, Button, Modal } from "antd";
import Footer from "../../components/footer/footer";
import HeaderText from "../../components/headerText/headerText";
const { Title, Paragraph, Text, Link } = Typography;

const aboutText = [
  {
    title: "Racing Introduction",
    en: "This racing is not related to citations. This racing is designed to evaluate the best articles rather than people, and it is not recommended to use it as a ranking criterion for authors, like ESI ranking.",
    cn: "本竞赛与被引次数无关。本竞赛旨在评初最佳文章，而非对作者的排名，因此不建议将其用作作者排名的依据，例如ESI排名等。",
  },
  {
    title: "Racing Frequency",
    en: "The racing is held three times a year, with Red (January to April), Green (May to August), and White season (September to December).",
    cn: "比赛每年举行三次，分为红中赛季（1月至4月）、发财赛季（5月至8月）和白板赛季（9月至12月）。",
  },
  {
    title: "Racing Process and Principle",
    en: "AI selects the article with the highest impact factor published by random authors in the last season as their entry for the racing.AI scores the paper's innovation level from five dimensions: Question, Theory, Practical, Effect, and Conclusion, and selects the average score of the two highest dimensions as the paper's score.",
    cn: "AI会随机选取100名作者在上赛季发表的影响因子最高的文章作为参赛作品，发布赛季初版排名。AI会从问题、理论、实践、效果和总结这五个维度对论文的创新程度进行评分，并选择其中两个最高维度的平均分作为论文的得分。",
  },
];

export default function About({ lang }) {
  return (
    <div className={styles.about}>
      <HeaderText lang={lang} />
      <Typography>
        {aboutText.map((text) => {
          return (
            <Paragraph>
              <Title level={4}>{text.title}</Title>
              <Text>{text[lang]}</Text>
            </Paragraph>
          );
        })}
      </Typography>
      <video
        muted
        loop="loop"
        controls="controls"
        src={`https://ff-kw.oss-cn-zhangjiakou.aliyuncs.com/rankings/video/Racing%20${
          lang == "en" ? "Eng" : "Chn"
        }.mp4`}
      ></video>
      <Footer lang={lang} />
    </div>
  );
}
