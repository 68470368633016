import React from "react";
import styles from './index.scss'

export default function Footer({ lang }) {
  return (
    <div className={styles.footer}>
      <p>
        {lang == "en"
          ? "If you have any questions, please send to "
          : "如有疑问，请联系 "}{" "}
        racing.spidermatrix@gmail.com
      </p>
    </div>
  );
}
