import React, { useEffect, useState } from "react";
import styles from "./index.scss";
import { Table, Typography, Button, Modal } from "antd";
import * as echarts from "echarts/core";
import { PolarComponent } from "echarts/components";
import { RadarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import axios from "axios";
import { tabs } from "../../constant";
const { Title, Paragraph, Text, Link } = Typography;
echarts.use([PolarComponent, RadarChart, CanvasRenderer]);

const keys = [
  "subject",
  "question",
  "theoretical_method",
  "practical_method",
  "effects",
  "conclusion",
  "summary",
];
const ranking = [
  {
    en: "This paper index is too low to join the racing, please try another one.",
    cn: "该论文创新因子较低，无法计入排名。",
  },
  {
    en: "This paper had already joined the racing.",
    cn: "该论文已加入竞赛，可在表中查看或因为因子较低未计入排名。",
  },
  {
    en: "Sorry, this paper cannot join the racing.",
    cn: "对不起，该论文无法加入排名。",
  },
];

export default function DetailsModal({
  type,
  lang,
  info,
  setIsModalOpen,
  isModalOpen,
}) {
  const [linkId, setLinkId] = useState();
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const modalRef = (obj) => {
    if (obj) {
      drawChart(obj, [
        {
          value: [
            info.question || 10,
            info.theoretical_method || 10,
            info.practical_method || 10,
            info.effects || 10,
            info.conclusion || 10,
          ],
        },
      ]);
    }
  };
  useEffect(() => {
    if (type == "submit") {
      console.log(info);
      if (info.subject == "Materials Science" || info.subject == "Chemistry") {
        setLinkId(1)
      } else {
        const ff = tabs.filter((tab) => tab.en == info.subject);
        ff.length == 1 && setLinkId(ff[0].id)
      }
    }
  }, [info, type]);
  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      wrapClassName="detailModal"
    >
      <Typography className={styles.detailItem}>
        {/* <Title level={4}>Score</Title>
        <div style={{ width: "100%" }}>
          <div
            id="quintuple"
            style={{
              width: "450px",
              height: "350px",
            }}
            ref={modalRef}
          />
        </div> */}
        {type == "submit" && (
          <Title level={3}>Racing index of {info.doi}</Title>
        )}
        {keys.map((key, index) => {
          let tmp = key.includes("_") ? key.replace("_", " ") : key;
          return (
            <Paragraph key={index}>
              <Title level={4}>
                {index == 0
                  ? "Subject"
                  : tmp.replace(tmp[0], tmp[0].toUpperCase())}
              </Title>
              {info[key]}
            </Paragraph>
          );
        })}
        {type == "home" && (
          <Paragraph>
            <Text strong>DOI:</Text>
            {info.doi}
          </Paragraph>
        )}
        {type == "submit" && (
          <>
            <Paragraph style={{ fontSize: 20 }}>
              <Text strong style={{ fontSize: 20 }}>
                {lang == "en"
                  ? `Rank in 2024 Current season: `
                  : `该论文在当前赛季的排名为: `}
              </Text>
              {info.rank}
            </Paragraph>
            <Paragraph>
              <Link
                underline
                italic
                href={`/subject/${linkId}?lang=${lang}&check=${encodeURIComponent(info.doi)}`}
              >
                Check the position of this paper.
              </Link>
            </Paragraph>
          </>
        )}
        {/* <Paragraph>
          <Link
            underline
            italic
            href={`https://www.spider-matrix.win/search/${encodeURIComponent(
              info.doi
            )}`}
            target="_blank"
          >
            Click to see full report
          </Link>
        </Paragraph> */}
      </Typography>
    </Modal>
  );
}

function drawChart(dom, data) {
  let myChart = echarts.getInstanceByDom(dom); //有的话就获取已有echarts实例的DOM节点。
  if (myChart == null) {
    // 如果不存在，就进行初始化。
    myChart = echarts.init(dom);
  }
  //此处省略数据处理过程
  let option = {
    angleAxis: {
      startAngle: 0,
    },
    radiusAxis: {
      type: "value",
      min: 0,
      max: 100,
      z: 1,
    },
    polar: {
      radius: 100,
    },
    radar: {
      shape: "circle",
      radius: 100,
      color: "black",
      indicator: [
        {
          name: "Problem",
          max: 100,
        },
        {
          name: "Theoretical Method",
          max: 100,
        },
        {
          name: "Practical Method",
          max: 100,
        },
        {
          name: "Effects",
          max: 100,
        },
        {
          name: "Conclusion",
          max: 100,
        },
      ],
      axisName: {
        color: "black",
      },
      axisNameGap: 20,
    },
    series: [
      {
        // name: "Five-dimensional analysis",
        type: "radar",
        data: data,
        areaStyle: {
          color: "#0068c9",
          opacity: 0.2,
        },
        label: {
          show: true,
        },
      },
    ],
  };
  option && myChart.setOption(option);
}
