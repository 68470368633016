import React, { createRef, useEffect, useRef, useState } from "react";
import styles from "./index.scss";
import { ossServer, ossServer1, tabs } from "../../constant";
import { Table, Typography, Button, Modal, Spin } from "antd";
import { xlsxToJson } from "~/utils";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/footer/footer";
import DetailsModal from "../../components/detailsModal/detailsModal";
import { getSearch } from "../../utils";
import { get } from "../../request";
import HeaderText from "../../components/headerText/headerText";
const { Title, Paragraph, Text, Link } = Typography;

const urls = [
  "https://ff-kw.oss-cn-zhangjiakou.aliyuncs.com/rankings/Materials_people_2023_bai_20240404.xlsx",
  "https://ff-kw.oss-cn-zhangjiakou.aliyuncs.com/rankings/Computer_people_2024_Zhong.xlsx",
  "https://ff-kw.oss-cn-zhangjiakou.aliyuncs.com/rankings/Physics_people_2024_Zhong.xlsx",
  "https://ff-kw.oss-cn-zhangjiakou.aliyuncs.com/rankings/Biology_people_2024_Zhong.xlsx",
];

export default function Home({ lang }) {
  const navigate = useNavigate();
  const location = useLocation();
  const tRef = useRef();
  const [authorDatas, setAuthorDatas] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [info, setInfo] = useState();
  const [authorColumns, setAuthorColumns] = useState();
  const [spinning, setSpinning] = useState(false);

  const getDatas = (id) => {
    get({
      url: `/racing/rank?subject=${id == 1 ? 'Materials%20Science,Chemistry' : encodeURIComponent(tabs[id].param)}`,
      onSuccess: (res) => {
        res.length = res.length >= 100 ? 100 : res.length;
        res.forEach((item, index) => {
          item["key"] = item.doi;
          item["index"] = index + 1;
        });
        setAuthorDatas([...res]);
      },
    });
  };

  useEffect(() => {
    if (location.pathname.includes("subject")) {
      let id = location.pathname.substring(9);
      setTimeout(() => {
        getDatas(id);
      }, 1000);
    } else if (location.pathname == "/") {
      setTimeout(() => {
        getDatas(1);
      }, 1000);
    }
  }, [location]);

  useEffect(() => {
    setAuthorColumns([
      {
        title: lang == "en" ? "Index" : "排名",
        dataIndex: "index",
        width: 70,
      },
      {
        title: "DOI",
        dataIndex: "doi",
        width: 155,
        render: (_, record) => (
          <a
            style={{ color: "#0e92ef" }}
            onClick={() => {
              setSpinning(true)
              axios({
                url: `https://www.knowledgeworks.chat/api/paper_analysis/?doi=${record.doi}`,
                headers: { "Content-Type": "application/json" },
                method: "get",
              }).then(({ data }) => {
                setSpinning(false)
                let str = {
                  ...data.summary,
                  doi: record.doi,
                };
                setInfo(str);
                setIsModalOpen(true);
              });
            }}
          >
            {record.doi}
          </a>
        ),
      },
      {
        title: lang == "en" ? "Score" : "分数",
        dataIndex: "score",
        width: 60,
      },
      {
        title: lang == "en" ? "Last author" : "最后作者",
        dataIndex: "lastAuthor",
        width: 100,
      },

      {
        title: lang == "en" ? "Institution" : "单位",
        dataIndex: "institution",
        width: 150,
      },
      {
        title: lang == "en" ? "Country" : "国家",
        dataIndex: "country",
        width: 70,
        render: (_, record) => (
          <div className={styles.country}>
            {record.country && <img src={`${ossServer1}/country/${record.country}.png`} alt="" />}
          </div>
        ),
      },
    ]);
  }, [lang]);

  // 给某一行设置不同的背景色
  const rowClassName = ({ key }) => {
    if (location.search != "") {
      if (key == decodeURIComponent(getSearch(location.search).check)) {
        return `${styles.specialRow}`;
      }
    }
    return "";
  };

  // 滚动到指定位置
  useEffect(() => {
    if (tRef && authorDatas.length != 0) {
      let d = document.getElementsByClassName(styles.specialRow)[0];
      d && d.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [tRef, authorDatas]);

  return (
    <div className={styles.home}>
      <HeaderText lang={lang} />
      <Typography className={styles.box1}>
        <div className={styles.authors}>
          <Table
            ref={tRef}
            rowKey="key"
            columns={authorColumns}
            dataSource={authorDatas}
            pagination={{
              pageSize: authorDatas.length,
              hideOnSinglePage: true,
            }}
            rowClassName={rowClassName}
          />
        </div>
      </Typography>
      <Footer lang={lang} />
      {info && (
        <DetailsModal
          type="home"
          lang={lang}
          info={info}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      <Spin spinning={spinning} fullscreen />
    </div>
  );
}
