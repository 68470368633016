import React, { useEffect, useState } from "react";
import styles from "./head.scss";
import { ossServer, tabs } from "~/constant";
import { TranslationOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getSearch } from "~/utils";

export default function Head({ setLang, lang }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(tabs[0].en);

  // 刷新后根据pathname选中tab
  useEffect(() => {
    if (location.pathname.includes("subject")) {
      let id = location.pathname.substring(9);
      tabs.forEach((it) => {
        if (it.id == id) {
          setCurrentTab(it.cn);
        }
      });
    } else if (location.pathname.includes("join_racing")) {
      setCurrentTab(tabs[5].cn);
    } else if (location.pathname.includes("history")) {
      setCurrentTab(tabs[6].cn);
    } else if (location.pathname.includes("/")) {
      setCurrentTab(tabs[0].cn);
    }
  }, [location]);

  // 根据url确定语言
  useEffect(() => {
    if (
      getSearch(location.search) &&
      getSearch(location.search).lang &&
      getSearch(location.search).lang != lang
    ) {
      setLang(getSearch(location.search).lang);
    }
  }, []);

  useEffect(() => {
    if (lang) {
      if (location.search.indexOf("lang=") != -1) {
        navigate({
          pathname: location.pathname,
          search: location.search.replace(
            `lang=${lang == "en" ? "cn" : "en"}`,
            `lang=${lang}`
          ),
        });
      } else {
        navigate({
          pathname: location.pathname,
          search: `lang=${lang}`,
        });
      }
    }
  }, [lang]);

  return (
    <div className={styles.head}>
      <div className={styles.logos}>
        <Link className={styles.logo} to="/">
          <img src={`${ossServer}/web/logo.ico`} />
          <h3>Spider Matrix</h3>
        </Link>
        <div className={styles.right}>
          <a href="https://www.spider-matrix.win/" target="_blank">
            {lang == "en" ? "Innovation" : "创新"}
          </a>
          <a href="https://blog.spider-matrix.win/" target="_blank">
            {lang == "en" ? "Blog" : "博客"}
          </a>
          <TranslationOutlined
            onClick={() => {
              setLang(lang == "en" ? "cn" : "en");
            }}
          />
        </div>
      </div>
      <div className={styles.tabs}>
        {tabs.map((tab) => {
          return (
            tab.id != 7 && (
              <Tab
                key={tab.id}
                tab={tab}
                active={tab.cn === currentTab}
                onClick={() => {
                  setCurrentTab(tab.cn);
                }}
                lang={lang}
              />
            )
          );
        })}
      </div>
    </div>
  );
}

function Tab({ lang, tab, active, onClick }) {
  return (
    <Link
      to={
        tab.id == 0
          ? `/about?lang=${lang}`
          : tab.id == 5
          ? `/join_racing?lang=${lang}`
          : tab.id == 6
          ? `/history?lang=${lang}`
          : `/subject/${tab.id}?lang=${lang}`
      }
      className={`${styles.tab} ${active ? styles.active : ""}`}
      onClick={onClick}
    >
      <span>{lang == "en" ? tab.en : tab.cn}</span>
    </Link>
  );
}
