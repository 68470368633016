import React, { useEffect, useState } from "react";
import styles from "./index.scss";
import { doiReg, ossServer } from "../../constant";
import { Typography, Button, Input, message, Spin } from "antd";
import * as echarts from "echarts/core";
import { PolarComponent } from "echarts/components";
import { RadarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import Footer from "../../components/footer/footer";
import moment from "moment";
import OSS from "ali-oss";
import PayModal from "../../components/pay/payModal";
import DetailsModal from "../../components/detailsModal/detailsModal";
import axios from "axios";
import { get, post } from "~/request";
import HeaderText from "../../components/headerText/headerText";
const { Title, Paragraph } = Typography;
echarts.use([PolarComponent, RadarChart, CanvasRenderer]);

export default function Submit({ lang }) {
  const [doi, setDoi] = useState();
  const [info, setInfo] = useState();
  const [payModalVisible, setPayModalVisible] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [spinning, setSpinning] = useState(false);

  // 上传存储doi数据的txt文件到oss
  // const handleClick = async () => {
  //   if (doi && doiReg.test(doi)) {
  //     // const accessKeyId = "LTAI5tBEffujprnjyE2N9raK";
  //     // const accessKeySecret = "CjOf9YV3fHAwFol4DS8VHwG1pAwdfL";

  //     // const client = new OSS({
  //     //   region: "oss-cn-hongkong", // 地域
  //     //   accessKeyId,
  //     //   accessKeySecret,
  //     //   bucket: "spider-matrix", // OSS Bucket 名称
  //     // });

  //     // // 获取当前时间作为文件名
  //     // const currentTime = moment().format("YY-MM-DD hh:mm:ss");
  //     // // 创建纯文本 Blob 对象
  //     // const contentBlob = new Blob([doi], { type: "text/plain" });

  //     // // 上传 txt 文件到 OSS 指定地址
  //     // client
  //     //   .put(`rankings/submitted_doi/${currentTime}.txt`, contentBlob)
  //     //   .then(function (result) {
  //     //     message.success(lang == "en" ? "Upload Success" : "上传成功");
  //     //     window.open(
  //     //       `https://www.spider-matrix.win/search/${encodeURIComponent(doi)}`
  //     //     );
  //     //     console.log("Upload Success:", result);
  //     //   })
  //     //   .catch(function (err) {
  //     //     message.error(lang == "en" ? "Upload Failed" : "上传失败");
  //     //     console.error("Upload Failed:", err);
  //     //   });
  //     window.open(
  //       `https://www.spider-matrix.win/search/${encodeURIComponent(doi)}`
  //     );
  //   } else {
  //     message.warning("Please enter the correct doi!");
  //   }
  // };

  const handleClick = () => {
    if (doi && doiReg.test(doi)) {
      setPayModalVisible(true);
    } else {
      message.warning("Please enter the correct doi!");
    }
  };

  const getInfo = ({ externalOrderId, payMethod }) => {
    console.log(externalOrderId, payMethod);
    setSpinning(true);
    post({
      url: "/racing/join",
      data: {
        doi,
      },
      timeout: 600000,
      ignoreError: true,
      onSuccess: (res) => {
        const summary = JSON.parse(res.ext).summary;
        const str = {
          ...summary,
          doi: res.doi,
        };
        if (
          summary.subject == "Materials Science" ||
          summary.subject == "Chemistry"
        ) {
          get({
            url: `/racing/rank?subject=Materials%20Science,Chemistry`,
            onSuccess: (data) => {
              data.forEach((item, index) => {
                if (item.doi == res.doi) {
                  str["rank"] = index + 1;
                  setInfo(str);
                  setSpinning(false);
                  setIsModalOpen(true);
                }
              });
            },
          });
        } else {
          str["rank"] = res.rank;
          setInfo(str);
          setSpinning(false);
          setIsModalOpen(true);
        }
      },
      onFail: (error, msg) => {
        console.log(error, msg);
        if (error == 10140) {
          setSpinning(false);
          message.warning(msg);
        }
        if (error.code == "ECONNABORTED") {
          setSpinning(false);
          message.warning("nonsupport!");
        }
      },
    });
  };

  return (
    <div className={styles.submit}>
      <HeaderText lang={lang} />
      <Typography>
        <div className={styles.input}>
          <Input
            size="large"
            className={styles.btn1}
            placeholder={`${
              lang == "en" ? "Your" : "论文"
            } DOI ( 2024.5 - 2024.8 )`}
            onChange={(e) => setDoi(e.target.value)}
          />
          <span>example: 10.1021/acs.nanolett.3c03416</span>
        </div>
        <Button className={styles.btn2} size="large" onClick={handleClick}>
          {lang == "en" ? "Join the Racing" : "加入竞赛"}!
        </Button>
      </Typography>
      <Footer lang={lang} />
      <PayModal
        payItem={5}
        postData={{ doi }}
        lang={lang}
        onSuccess={getInfo}
        visible={payModalVisible}
        setVisible={setPayModalVisible}
      />
      {info && (
        <DetailsModal
          type="submit"
          lang={lang}
          info={info}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      <Spin
        spinning={spinning}
        fullscreen
        tip={
          lang == "en"
            ? "Calculating paper index. This needs about 10 minutes."
            : "正在分析文章内容，请耐心等待。此过程可能需要约10分钟。"
        }
      />
    </div>
  );
}
