import React, { useEffect, useState } from "react";
import styles from "./index.scss";
import { Table, Typography, Button, Modal } from "antd";
import Footer from "../../components/footer/footer";
import { xlsxToJson } from "../../utils";
const { Title, Paragraph, Text, Link } = Typography;

export default function AiPrediction({ lang }) {
  const [data, setData] = useState([]);

  const columns = [
    {
      title: "链上序号",
      dataIndex: "链上序号",
      key: "链上序号",
    },
    {
      title: "预测时间",
      dataIndex: "预测时间",
      key: "预测时间",
    },
    {
      title: "被预测DOI",
      dataIndex: "被预测DOI",
      key: "被预测DOI",
    },
    {
      title: "发表时间",
      key: "发表时间",
      dataIndex: "发表时间",
    },
    {
      title: "文章内容",
      key: "文章内容",
      dataIndex: "文章内容",
    },
  ];

  useEffect(() => {
    (async () => {
      const json = await xlsxToJson(
        "https://ff-kw.oss-cn-zhangjiakou.aliyuncs.com/rankings/AI_predict_accuracy.xlsx"
      );
      setData([...json]);
    })();
  }, []);
  return (
    <div className={styles.ai}>
      <Title level={1}>
        {lang == "en"
          ? "Racing of Academic Papers"
          : "学术论文创新大赛"}
      </Title>
      <Title level={2}>
        {lang == "en" ? "AI idea prediction track" : "AI idea预测赛道"}
      </Title>
      <p>Polygon合约地址: 0xDFbA614978eDed4E2C1bC039e081058518E0E6B5</p>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: data.length,
          hideOnSinglePage: true,
        }}
        scroll={{
          x: 800,
          y: 600,
        }}
      />
      <Footer lang={lang} />
    </div>
  );
}
