import React, { useEffect, useState } from "react";
import styles from "./app.scss";
import Head from "./components/head/head";
import { get, post } from "./request";
import Router from "./router";
import moment from "moment";
import { loginCodeKey, ossServer, tabs } from "./constant";

export default function App() {
  const [lang, setLang] = useState("en");
  const [user, setUser] = useState({});

  const viewAt = moment().valueOf();
  const gapSecs = 10;
  let viewId = 0;

  const pageView = () => {
    post({
      url: "/fa/view",
      data: {
        viewAt,
        time: gapSecs,
        id: viewId,
        uid: window.user?.id || 0,
      },
      onSuccess: (view) => {
        viewId = view.id;
      },
      onFail: () => {},
    });
  };

  useEffect(() => {
    get({
      url: "/user/info",
      onSuccess: (data) => {
        setUser(data);
      },
      onFail: () => {
        setUser(null);
        loginByCode();
      },
    });
    insertPaypal();

    const handler = setInterval(pageView, gapSecs * 1000);
    return () => {
      clearInterval(handler);
    };
  }, []);

  const loginByCode = () => {
    let codeInfo = localStorage.getItem(loginCodeKey);
    post({
      url: "/login/code",
      data: {
        code: codeInfo || null,
      },
      onSuccess: (data) => {
        const { user } = data;
        setUser(user);
        window.user = user;
        localStorage.setItem(loginCodeKey, user.code);
      },
    });
  };

  const insertPaypal = () => {
    get({
      url: "/paypal/client_id",
      onSuccess: (clientId) => {
        const script = document.createElement("script");
        script.setAttribute(
          "src",
          `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=USD`
        );
        document.body.appendChild(script);
      },
    });
  };

  return (
    <div>
      <Head user={user} setLang={setLang} lang={lang} />
      <div className={styles.main}>
        <Router user={user} lang={lang} />
      </div>
    </div>
  );
}


