import axios from "axios";
import * as XLSX from "xlsx";

export function goto(url) {
  window.location.href = url;
}

let loadingCount = 0;

export function showLoading() {
  if (!loadingCount) {
    const el = document.createElement("div");
    el.id = "loading-mask";
    document.body.appendChild(el);
  }
  loadingCount += 1;
}

export function hideLoading() {
  loadingCount -= 1;
  if (!loadingCount) {
    const mask = document.getElementById("loading-mask");
    mask && mask.remove();
  }
}

export function getShape(id) {
  let el = document.getElementById(id);
  const width = el.offsetWidth;
  const height = el.offsetHeight;
  let top = el.offsetTop;
  let left = el.offsetLeft;
  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }
  return { width, height, top, left };
}

// 解析search传参
export function getSearch(str) {
  if (str.indexOf("?") != -1) {
    let obj = {};
    str
      .split("?")[1]
      .split("&")
      .map((el) => {
        let arr = el.split("=");
        obj[arr[0]] = arr[1];
      });
    return obj;
  } else {
    return;
  }
}

export function clearToken() {
  localStorage.removeItem("X-Token");
  localStorage.removeItem("X-User-Id");
}

//#region xlsx(oss) to json
async function getFileStream(url) {
  const response = await axios.get(url, { responseType: "blob", Pragma: 'no-cache' });
  return response.data;
}

async function convertFileStreamToWorkbook(fileStream) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (event) => {
      try {
        const workbook = XLSX.read(event.target.result, { type: "binary" });
        resolve(workbook);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsBinaryString(fileStream);
  });
}

async function convertWorkbookToJson(workbook) {
  const sheetName = workbook.SheetNames[0]; // 获取第一个工作表的名称
  const worksheet = workbook.Sheets[sheetName]; // 获取第一个工作表对象
  const jsonData = XLSX.utils.sheet_to_json(worksheet); // 将工作表对象转换为JSON对象
  return jsonData;
}

export async function xlsxToJson(OSSUrl) {
  const fileStream = await getFileStream(OSSUrl);
  const workbook = await convertFileStreamToWorkbook(fileStream);
  const jsonData = await convertWorkbookToJson(workbook);
  return jsonData;
}
//#endregion
