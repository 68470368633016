export const ossServer = "https://spider-matrix.oss-cn-hongkong.aliyuncs.com";
export const ossServer1 = "https://aixiv.oss-cn-zhangjiakou.aliyuncs.com";
export const doiReg = /^(10[.][0-9]{4,}(?:[.][0-9]+)*\/(?:(?!["&\'<>])\S)+)$/;
export const patentReg = /^.*$/; // TODO
export const innovateKey = "INNOVATE";
export const loginCodeKey = "CODE";
export const cipherKey = "CIPHER";
export const tabs = [
  {
    id: 0,
    en: "About",
    param: "About",
    cn: "关于我们",
  },
  {
    id: 1,
    en: "Materials&Chemistry",
    param: "Materials Science",
    cn: "材料化学赛道",
  },
  {
    id: 2,
    en: "Computer Science",
    param: "Computer Science",
    cn: "计算机科学赛道",
  },
  {
    id: 3,
    en: "Physics",
    param: "Physics",
    cn: "物理赛道",
  },
  {
    id: 4,
    en: "Biology",
    param: "Biology",
    cn: "生物赛道",
  },

  {
    id: 5,
    en: "Join Racing",
    param: "Join Racing",
    cn: "参与比赛",
  },
  {
    id: 6,
    en: "Previous Racing",
    param: "Previous Racing",
    cn: "历史赛季",
  },
  {
    id: 7,
    en: "AI prediction accuracy",
    param: "AI prediction accuracy",
    cn: "AI预测准确率",
  },
];
