import React, { useEffect, useState } from "react";
import styles from "./index.scss";
import { ossServer, ossServer1, tabs } from "~/constant";
import { Table, Typography, Button, Modal, Form, Select, Spin } from "antd";
import axios from "axios";
import { get } from "~/request";
import DetailsModal from "../../components/detailsModal/detailsModal";
const { Title, Paragraph, Text, Link } = Typography;

export default function History({ lang }) {
  const [datas, setDatas] = useState([]);
  const [columns, setColumns] = useState();
  const [year, setYear] = useState();
  const [season, setSeason] = useState();
  const [subject, setSubject] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [info, setInfo] = useState();
  const [spinning, setSpinning] = useState(false);

  useEffect(() => {
    setColumns([
      {
        title: lang == "en" ? "Index" : "排名",
        dataIndex: "index",
        width: 70,
      },
      {
        title: "DOI",
        dataIndex: "doi",
        width: 155,
        render: (_, record) => (
          <a
            style={{ color: "#0e92ef" }}
            onClick={() => {
              setSpinning(true);
              axios({
                url: `https://www.knowledgeworks.chat/api/paper_analysis/?doi=${record.doi}`,
                headers: { "Content-Type": "application/json" },
                method: "get",
              }).then(({ data }) => {
                setSpinning(false);
                let str = {
                  ...data.summary,
                  doi: record.doi,
                };
                setInfo(str);
                setIsModalOpen(true);
              });
            }}
          >
            {record.doi}
          </a>
        ),
      },
      {
        title: lang == "en" ? "Score" : "分数",
        dataIndex: "score",
        width: 60,
      },
      {
        title: lang == "en" ? "Last author" : "最后作者",
        dataIndex: "lastAuthor",
        width: 100,
      },

      {
        title: lang == "en" ? "Institution" : "单位",
        dataIndex: "institution",
        width: 150,
      },
      {
        title: lang == "en" ? "Country" : "国家",
        dataIndex: "country",
        width: 70,
        render: (_, record) => (
          <div className={styles.country}>
            {record.country && (
              <img src={`${ossServer1}/country/${record.country}.png`} alt="" />
            )}
          </div>
        ),
      },
    ]);
  }, [lang]);

  useEffect(() => {
    if (year && season && subject) {
      get({
        url: `/racing/rank?subject=${
          subject == 1
            ? "Materials%20Science,Chemistry"
            : encodeURIComponent(tabs[subject].param)
        }&time=${year + season}`,
        onSuccess: (res) => {
          res.length = res.length >= 100 ? 100 : res.length;
          res.forEach((item, index) => {
            item["key"] = item.doi;
            item["index"] = index + 1;
          });
          setDatas([...res]);
        },
      });
    }
  }, [year, season, subject]);
  return (
    <div className={styles.history}>
      <Form layout="inline">
        <Form.Item label={lang == "en" ? "Year" : "年份"}>
          <Select
            style={{
              width: 90,
            }}
            onChange={(year) => setYear(year)}
            options={[
              {
                value: "2024",
                label: "2024",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label={lang == "en" ? "Season" : "赛季"}>
          <Select
            style={{
              width: 90,
            }}
            onChange={(season) => setSeason(season)}
            options={[
              {
                value: "1",
                label: lang == "en" ? "Red" : "红中",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label={lang == "en" ? "Subject" : "学科"}>
          <Select
            style={{
              width: 190,
            }}
            onChange={(subject) => setSubject(subject)}
            options={[
              {
                value: 1,
                label: lang == "en" ? "Materials&Chemistry" : "材料化学赛道",
              },
              {
                value: 2,
                label: lang == "en" ? "Computer Science" : "计算机科学赛道",
              },
              {
                value: 3,
                label: lang == "en" ? "Physics" : "物理赛道",
              },
              {
                value: 4,
                label: lang == "en" ? "Biology" : "生物赛道",
              },
            ]}
          />
        </Form.Item>
      </Form>
      {datas.length != 0 && (
        <Table
          columns={columns}
          dataSource={datas}
          pagination={{
            pageSize: datas.length,
            hideOnSinglePage: true,
          }}
        />
      )}
      {info && (
        <DetailsModal
          type="home"
          lang={lang}
          info={info}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      <Spin spinning={spinning} fullscreen />
    </div>
  );
}
