import React from "react";
import styles from "./index.scss";
import { ossServer } from "../../constant";

export default function HeaderText({ lang }) {
  return (
    <div
      className={styles.headerText}
      style={{
        backgroundImage: `url(${ossServer}/racing/racingBgImg.png)`,
        backgroundSize: "cover",
      }}
    >
      <div className={styles.title}>
        <h1>
          {lang == "en" ? "Racing of Academic Papers" : "学术论文创新大赛"}
        </h1>
        <h2>
          {lang == "en"
            ? "2024 Green season (May.2024 - Aug.2024)"
            : "2024 发财赛季 (2024年5月 - 2024年8月)"}
        </h2>
        <h1 className={styles.special}>
          “
          {lang == "en"
            ? "The Science is about Novelty but not Citations."
            : "科学应当是创新的体现而不是引用量的比较。"}
          ”
        </h1>
      </div>
      <div className={styles.img}>
        <img src={`${ossServer}/racing/racing4.png`} alt="" />
      </div>
    </div>
  );
}
